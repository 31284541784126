.plone-toolbar-top.plone-toolbar-expanded #edit-zone nav>ul>li {
    background-color: #000;
}

li#portaltab-index_html {
    margin-left: -15px;
}

.contentwellportlets   {
    margin-left: 0 !important;
}
#portaltop-right * {
    color: white;
    text-decoration: none !important;    
}
#portal-languageselector li a {
  color: black;
  text-decoration:none;
}

#portal-languageselector li a:hover {
    color: @fgr-hover;
}    

li.currentLanguage a, li.currentLanguage a:hover {
    color: #999 !important;
}    

.plone-toolbar-expanded ul#portal-languageselector {
    top: 1%;
    right: 2%
}

#portal-footer-wrapper, #portal-colophon a {
  color: white;
}
#portal-colophon a:hover  {
color: @fgr-hover ;
text-decoration:none;
}
a.khg-bulletlink {
  color: black;
  background: url("squarebul_10.png") no-repeat scroll left center transparent;
  padding-left: 12px;
  border-bottom:none !important;
  text-decoration:none;
}
a.khg-bulletlink:hover {
  color: @fgr-hover !important;
  background: url("squarebul_10hover.png") no-repeat scroll left center transparent;
}

i.link-external:after {
    font-size: 80%;
  }
.portletNavigationTree {
  background:none;
  border:none;
}  

.portletNavigationTree ul > li > a {
    border-top:none !important;
}
.navTreeCurrentItem {
    font-weight: bold;    
}
/*
#portlets-in-header li{
    margin: 0 !important;
}
*/
#portlets-in-header  img {
  //display:none;
  object-fit: cover !important;
  width:100%;
}

//.outer-wrapper,#portal-footer-wrapper {
/* body {  
  max-width: 600px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
*/
#edit-zone   {
  z-index:1010 !important;
}
body {
  
  background-color: #fff ;
}
.outer-wrapper {
  
  background-color: #fff ;
  min-height:91vh;
}

// remove toolbar for authenticated members when no permission to edit, contribute, review 
// see also: http://stackoverflow.com/questions/32824931/how-to-disable-plone-5-toolbar-for-authenticated-user-group
// body.userrole-anonymous 
body.userrole-member #edit-zone {
  display:none;
}
.plone-toolbar-top-expanded {
    margin-top: 50px;
  }
body.userrole-member.plone-toolbar-top-expanded,
body.userrole-authenticated.plone-toolbar-left-expanded {
     margin-top: 0;
}
body.userrole-member,
body.userrole-authenticated {
     padding-left:0
}     
body.userrole-editor #edit-zone,
body.userrole-contributor #edit-zone,
body.userrole-reviewer #edit-zone {
  display:inline-block;
}
body.userrole-editor.plone-toolbar-top-expanded,
body.userrole-contributor.plone-toolbar-top-expanded,
body.userrole-reviewer.plone-toolbar-top-expanded,
body.userrole-owner.plone-toolbar-top-expanded,
body.userrole-manager.plone-toolbar-top-expanded  {
    margin-top: 50px;
  }
/*  
body.userrole-editor.plone-toolbar-left-expanded,
body.userrole-contributor.plone-toolbar-left-expanded,
body.userrole-reviewer.plone-toolbar-left-expanded,
body.userrole-owner.plone-toolbar-left-expanded,
body.userrole-manager.plone-toolbar-left-expanded  {
    padding-left: 120px;
  } 
*/  
body.userrole-editor.plone-toolbar-top-expanded #portal-languageselector,
body.userrole-contributor.plone-toolbar-top-expanded #portal-languageselector,
body.userrole-reviewer.plone-toolbar-top-expanded #portal-languageselector,
body.userrole-owner.plone-toolbar-top-expanded #portal-languageselector,
body.userrole-manager.plone-toolbar-top-expanded #portal-languageselector {
  margin-top:100px;
  z-index:1000
}


#portal-personaltools-2 {
    position: absolute;
    top: 10px;
    float: left;
    right: 1%;
    z-index: 110;
    font-size: 100%;
    
  }
#portal-personaltools-2 * {
    color: black;
}

body.userrole-editor #portal-personaltools-2,
body.userrole-contributor #portal-personaltools-2 ,
body.userrole-owner #portal-personaltools-2,
body.userrole-manager #portal-personaltools-2 {
    display: none;
  }
/*
 #user-name::before {
    content: "▼ ";
  }
 */
.actionMenuContent {
  border:1px solid white;
  padding:8px}
#user-name  {
  font-size: 110%;
  font-weight: bold;
  color: white;
  text-decoration:none;
}
#user-name a:hover {
  color: @fgr-hover;
}

img.video-overlay {
    width: 100%;
}
body {
    -ms-overflow-style: none;
}

.portletStaticText > span:before {
  display:none;}
  

html {
  height: 100%;
}
body {
  min-height: 100% !important;
}

a.fgr-edit {
    font-size: 10px;
    color: red !important;
    position: absolute;
    top: 5%;
    border:1px red solid;
}

.dropdown-menu > li > a:hover{
  color:@fgr-hover;
}

.leftdiv33 {
  width:33%;
  padding: 10px;
}
.leftdiv33  img  {
  max-width:100%;
  height:auto;
}
//.leftdiv33 img
.rightdiv66 {
  width:66%
  padding: 10px;
  position: absolute;
  left: 34%;
  top: 0px;
}
/*
a:before {
  font-family: "Fontello";
  font-size: 0.9em;
  content: '\e806';  
}
*/
// Test-settings
/*
article#content {
    border: 1px #ddd solid;
}
#slsh-imagecontainer {
  border: #5cc 1px dotted;
}
.inside-pager-thumbs {
  border:blue 1px dashed;
}
.pager {
  border:1px solid red;
  
}
*/
div.table1 { 
      display: table; 
      border-collapse:collapse; 
    }
div.tr1 { 
      display:table-row; 
    }

    
    
@media (min-width: @plone-grid-float-breakpoint) {
    div.td25 {
      display:table-cell;
      width:25%; 
      padding:10px;
      vertical-align:top; 
   }
  div.td33 {
      display:table-cell;
      width:33.33%; 
      padding:10px;
      vertical-align:top; 
   }
  div.td50 {
      display:table-cell;
      width:50%; 
      padding:10px;
      vertical-align:top; 
   }  
  div.td66 { 
      display:table-cell; 
      width:66.66%; 
      padding:10px; 
      vertical-align:top;
    }    
  }

  .dt33  img, .dt66 img {
    max-width:90%;
    height:auto;
  }


.callout1{ 
  color: @plone-headings-color;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  }
  
  
.bglight {
  background-color: #e3eefc;
}
.contactbox {
  color: @fgr-hover;
  margin-left:40px !important;
  margin-right:auto;
  padding: 100px;
  //border:1px solid @fgr-hover;
  h3 {color: @fgr-hover}
  }

#accept_btn {
  background-color: @fgr-hover !important;
}

.back-to-top, .back-to-top:active, .back-to-top:visited, .back-to-top:hover {
    position: fixed;
    bottom: 5%;
    right: 3%;
    width: 55px;
    height: 55px;    
    z-index: 100;
    display: none;
    border:none !important;
}
.fgr-emph {
    color: darkred;
    font-size: 110%;
}
input#form-widgets-email_adresse {
    width: 40%;
}
