 /* Overlay font colors  */
.ovlight * {
  color:white;
}
.ovdark * {
  color:black;
}
.ovmedium * {
  color:#999;
}
.ovcolor1 * {
  color:#E0573C;
}
.ovcolor2 * {
  color:#FFFFA0;
}

#portlets-in-header  {
  width:100% !important;
  max-width: 100%;
}

/*
#fgrconslideshowportlet {
  float:auto;
}
div.pager {
  float:auto;
}
div.pager {
  border:1px solid blue;
}
*/
//        --------------------------------------
/* hide lead image in slideshow template  only use for summary views*/
.portaltype-slideshow-folder  div.leadImage {
  display:none;
}
/* pager */
.cycle-pager { 
    text-align: left; width: 100%; z-index: 900; position: absolute; top: 10px; overflow: hidden;
}
.cycle-pager span { 
    font-family: arial; font-size: 50px; width: 16px; height: 16px; 
    display: inline-block; color: #ddd; cursor: pointer; 
}
.cycle-pager span.cycle-pager-active { color: #D69746;}
.cycle-pager > * { cursor: pointer;}
.external > *{
  
}
.pager{
  z-index:2000 !important;
}
.slideshow-imagetitle {
   margin-top: 10px;
   font-weight:bold;
   text-align: center;
   position: relative;
   font-size:@plone-font-size-small;
}
.slideshow-imagedescription {
   position: relative;
   font-style:italic;
   text-align: center;
  
}
.imagecounter{
   position: relative;
   color:#999;
   font-size:0.7em;
   text-align: center;
   margin-top:10px;
  
}
/* caption */
.cycle-caption,#custom-caption { position: relative; 
color: black; 
font-weight:bolder;
margin-top: 5%; 
z-index: 400; 
text-align: center;}
.slsh-caption {
  position:relative;
  text-align:center;
  padding-top:4%;
  }
/* prev / next links */
.cycle-prev { left: 2%;  background: url(++resource++fgrcon.slideshow/left.png) 20% 50% no-repeat;}
.cycle-next { right: 2%; background: url(++resource++fgrcon.slideshow/right.png) 20% 50% no-repeat;}
.cycle-prev:hover, .cycle-next:hover { opacity: .7; filter: alpha(opacity=70) }

.disabled { opacity: .5; filter:alpha(opacity=50); }
.cycle-prev, .cycle-next { 
  position: absolute; 
  top: 0; 
  width: 5%; 
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 490;
  height: 100%;
  cursor: pointer;
  background-size: contain
  }


.pager {  overflow:hidden; }
.pagerbullet {background:#ccc; border-width:2px;border-color:#fff }
.pagerthumb {border:#ccc 2px solid;
             z-index:1000; }
.cycle-pager-active {background:red;} 
.cycle-pager-active.pagerthumb {border:red 2px solid;}
.cycle-pager-active.pagerbullet {}

.InHeaderPortletManager1 img{
   object-fit: cover !important;
 }

.slsh-image-counter {display:block; clear:both; font-size: smaller; font-weight:light}
.slsh-image-title {display:block; clear:both; font-size: normal; font-weight:normal}
.slsh-image-desc {display:block; clear:both; font-size: smaller; font-weight:lighter}

.center {
  text-align:center;
}

.slsh-img-caption, #image-caption {
   margin-top:0.5em;
   text-align:center;
}
