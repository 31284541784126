//*// FOOTER //*//

#portal-footer-wrapper {
  padding-top: 1%;
  background: url("bottom.jpg") no-repeat scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 //background-color: @plone-footer-bg;
 // color: @plone-gray-lighter;
  text-align: center;
  font-size: 120%;
  clear: both;
 /* p {
    padding-bottom: 28px;
  }*/
  abbr, ul {
    color: @plone-gray-lighter;
  }
  a {
    color: @plone-link-color-on-dark;
    &:hover {color: lighten(@plone-link-color-on-dark,15%);}
  }
  .portletWrapper {
    display: inline-block;
    &:first-child {
      display: block;
      /* border-bottom: 1px solid @plone-gray-dark;*/
      padding-bottom: 1em;
      margin-bottom: .5em;
    }
  }
  .portlet {
    background: transparent;
    border: 0;
    font-weight: @plone-font-weight-light;
    box-shadow: none;
    margin-bottom: 0;
    .portletContent {
      padding: 0;
      background: transparent;
      border: 0;
      > * {padding: inherit;}
      li {
        background: transparent;
        display: inline-block;
        border: 0;
        white-space: nowrap;
        padding: 10px 30px;
      }
    }
    &#portal-colophon .portletContent a { //remove if remove Powered by Plone
        padding: 10px 30px;
    }
    .portletItem:before, .portletItem a:before {
      content: "";
    }
    .portletItem a {
      padding: 0; border:0;
    }
    .portletActions {
      text-transform: uppercase;
      font-size: @plone-font-size-small;
      font-weight: @plone-font-weight-bold;
      margin-right: 15px;
    }
  }
}

#portal-siteactions {
  display: inline-block;
  text-transform: uppercase;
  font-size: @plone-font-size-small;
}
#portal-siteactions li {
  font-weight: bold;
  display: inline-block;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
}
#portal-colophon  div {
  background: url("bottom.jpg") no-repeat scroll;
}
